<template>
    <div id="user">
        <div class="mcontainer">
            <div class="flex justify-between relative md:mb-4 mb-3">
                <div class="flex-1">
                    <h2 class="text-lg font-semibold">Update User</h2>
                </div>
            </div>
            <div class="relative">
                <div class="card">
                    <div class="col-md-12 content-form">
                        <div class="image-uploader circle-content">
                            <input type="file" ref="images" @change="onFileSelected($event, 'images')" required>
                            <span @click="$refs.images.click()"><Icon icon="bi:cloud-upload" /></span>
                            <img :src="images ? images : $placeholder2" alt="images" class="circle-image-150">
                        </div>
                        <br><br>
                        <form @submit.prevent="">
                            <input type="text" v-model="datauser.fullname" class="form-control" placeholder="Nama">
                            <input type="text" v-model="datauser.no_induk" class="form-control" placeholder="NRP">
                            <input type="text" v-model="datauser.faculity" class="form-control" placeholder="Fakultas">
                            <input type="text" v-model="datauser.generation" class="form-control" placeholder="Angkatan">
                            <input type="text" v-model="datauser.ttl" class="form-control" placeholder="Tempat Tanggal Lahir">
                            <input type="text" v-model="datauser.email" class="form-control" placeholder="Email">
                            <input type="text" v-model="datauser.phone" class="form-control" placeholder="No. Telepon">
                            <input type="text" v-model="datauser.ig_username" class="form-control" placeholder="Username Instagram">
                            <input type="text" v-model="datauser.address" class="form-control" placeholder="Alamat">
                            <textarea class="form-control" v-model="datauser.about" cols="30" rows="10" placeholder="Tentang Anda"></textarea>
                            <button class="btn btn-primary" type="submit" @click="submitUser">Simpan</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    import { toFormData } from '@/mixins'

    export default {
        name: 'Setting',
        components: {
        },
        data () {
            return {
                datauser: {},
                images: '',
                loading: false,
                change_profile: false
            }
        },
        created () {
            this.fetchData()
        },
        watch: {
            $route: 'fetchData'
        },
        methods: {
            async fetchData () {
                this.loading = true
                try {
                    const res = await axios.get('user/' + this.$route.params.code)
                    this.loading = false
                    this.images = res.data.user.images
                    this.datauser = res.data.user
                } catch (e) {
                    console.error(e)
                }
            },
            submitUser () {
                const { fullname, faculity, generation, ttl, email, phone, address, about, images } = this.datauser
                const igUsername = this.datauser.ig_username
                const noInduk = this.datauser.no_induk
                const showTTL = this.datauser.show_ttl
                const showIG = this.datauser.show_ig
                const showNRP = this.datauser.show_nrp
                const showTelp = this.datauser.show_telp
                const $this = this

                axios.post('user/update/' + this.$route.params.code, toFormData({ fullname, no_induk: noInduk, faculity, generation, ttl, email, phone, ig_username: igUsername, address, about, images, show_ttl: showTTL, show_telp: showTelp, show_nrp: showNRP, show_ig: showIG }), {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(() => {
                    this.datauser.images = this.images
                    $this.$swal({
                        imageUrl: require('@/assets/images/vector-success.png'),
                        imageWidth: 400,
                        title: 'Berhasil Update',
                        text: 'Informasi profil berhasil disimpan.'
                    })
                })
                    .finally(() => {
                    })
            },
            onFileSelected (e, target) {
                this.datauser[target] = e.target.files[0]
                this[target] = URL.createObjectURL(this.datauser[target])
                this.change_profile = true
            }
        }
    }
</script>
